import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.3.56191" />
      <meta name="build-date" content="2025-02-03T19:14:34Z" />
    </Head>
  );
};

export default Versioning;
